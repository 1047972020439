<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6">
        <v-select
            v-if="patients.length > 0"
            v-model="selectedPatient"
            :items="patients"
            item-title="fullName"
            item-value="id"
            :label="$t('Select Patient')"
            :disabled="loadingPatients"
        />
      </v-col>
    </v-row>
    <v-row v-if="selectedPatient">
      <v-col cols="12">
        <v-btn :disabled="!selectedPatient" color="primary" @click="createProcedure">
          {{$t("Create Procedure")}}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="procedureCreated">
      <v-col cols="12">
        <v-alert type="success" dismissible>
          {{$t("Procedure created successfully with ID")}}: {{ targetProcedureId }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="selectedPatient">
      <v-col cols="12" sm="6">
        <v-select
            v-model="selectedSession"
            :items="replayableSessions"
            item-text="title"
            item-value="id"
            :label="$t('Select Care Plan Session')"
            :disabled="loadingSessions"
        />
      </v-col>
    </v-row>

    <v-row v-if="selectedPatient && selectedSession">
      <v-col cols="12">
        <v-btn color="primary" @click="createReplay">
          {{$t("Create replay")}}
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="replayCreated">
      <v-col cols="12">
        <v-alert type="success" dismissible>
          {{$t("Replay created successfully with ID")}}: {{ replayId }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="loadingResults">
      <v-col cols="12">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-col>
    </v-row>

    <v-row v-if="results && results.length > 0">
      <v-col cols="12">
        <v-card>
          <v-card-title>{{$t("Procedure Results")}}</v-card-title>
          <v-card-subtitle>{{$t("Click first to start the replay")}}</v-card-subtitle>
          <v-btn color="primary" @click="startReplay" :disabled="!replayCreated">
            {{$t("Start replay")}}
          </v-btn>
          <v-btn :disabled="!allAudiosLoaded" color="primary" @click="toggleAllAudios">{{ isPlaying ? $t('Pause All Audios') : $t('Play all audios') }}</v-btn>

          <audio
              ref="globalAudioPlayer"
              :src="audioCombinedUrl"
              controls
              class="custom-audio-player"
              @play="playAllAudios"
              @pause="pauseAllAudios"
              @timeupdate="syncAudioTime"
              @seeked="syncSeeked"
          ></audio>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="results && results.length > 0" align="end" justify="end" class="mt-4">
      <v-btn color="primary" @click="handleFinish">
        {{$t("Finish")}}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/scripts/api/api";

export default {
  data() {
    return {
      selectedSession: null,
      patients: [],
      selectedPatient: null,
      loadingPatients: false,
      replayableSessions: [],
      loadingResults: false,
      loadingSessions: false,
      results: null,
      patientDetails: {},
      originalProcedureId: null,
      originalUnitId: null,
      activityUnitId: null,
      targetProcedureId: null,
      eventSource: null,
      replayCreated: false,
      procedureCreated: false,
      replayId: null,
      currentAudioUrl: null,
      currentPlayingId: null,
      audioElements: [],
      isPlaying: false,
      allAudiosLoaded: false,
      audioCombinedUrl: null,
      combinedAudioBuffer: null,
    };
  },
  mounted() {
    this.loadPatients();
    this.loadReplayableSessions();
    this.getActiveProcedureStream();
  },
  watch: {
    selectedPatient() {
      this.onPatientSelect();
    }
  },
  beforeUnmount() {
    if (this.eventSource) {
      this.eventSource.close();
    }
  },
  methods: {

    async onPatientSelect() {
      this.patientDetails = this.patients.find(p => p.id === this.selectedPatient);
      if (this.patientDetails && this.patientDetails.id) {
        this.getActiveProcedureStream(this.patientDetails.id);
      }    },
    async createProcedure() {

      try {
        //const { carePlanUuid, carePlanUnitId, id } = this.patientDetails;
        const { carePlanUuid, id } = this.patientDetails;
        //const carePlanUnitId = 263 for Otto Gunther
        const carePlanUnitId = 281
        if (!carePlanUuid || !carePlanUnitId || !id) {
          console.error("Missing carePlanUuid or carePlanUnitId.");
          return;
        }

        const payload = {
          carePlanUuid,
          carePlanUnitId,
          patient: id,
        };

        const response = await api.postReplayProcedure(payload);
        console.log("Procedure created:", response);
        this.activityUnitId = response.nextActivityUnit.id;
        this.targetProcedureId = response.id;
        this.procedureCreated = true;

        console.log("Saved activityUnitId:", this.activityUnitId);
        console.log("Saved targetProcedureId:", this.targetProcedureId);

      } catch (error) {
        console.error("Error creating procedure:", error);
      }
    },
    async loadPatients() {
        this.loadingPatients = true;
        try {
          const user = JSON.parse(sessionStorage.getItem('user'));
          if (!user || !user.patients || user.patients.length === 0) {
            console.error("No patients found for the therapist.");
            return;
          }

          const patientDetailsPromises = user.patients.map(async (patientId) => {
            try {
              const patientDetails = await api.getPatientById(user.id, patientId);
              console.log("patientDetails", patientDetails);

              if (patientDetails && patientDetails.fhirPatient) {
                // Buscar el carePlanUnitId donde replay es true
                const carePlan = patientDetails.carePlans[0];
                const replayUnit = carePlan.units.find(unit => unit.activity.units.some(subUnit => subUnit.replay === true));

                const carePlanUnitId = replayUnit ? replayUnit.id : null;
                const carePlanUuid = patientDetails.carePlanUuids[0];
                return {
                  id: patientId,
                  carePlanUnitId,
                  carePlanUuid,
                  fullName: `${patientDetails.fhirPatient.firstName || ''} ${patientDetails.fhirPatient.lastName || ''}`.trim(),
                };
              } else {
                console.warn(`No details found for patientId: ${patientId}`);
                return null;
              }

            } catch (error) {
              console.error(`Error fetching patient details for ID: ${patientId}`, error);
              return null;
            }
          });

          // Esperar a que todas las promesas se completen y filtrar los resultados nulos
          const patientsDetails = await Promise.all(patientDetailsPromises);
          this.patients = [...patientsDetails.filter(patient => patient !== null)];
          this.patients = JSON.parse(JSON.stringify(this.patients));
          console.log("this.patients",this.patients)
        } catch (error) {
          console.error("Error loading patients:", error);
        } finally {
          this.loadingPatients = false;
        }
      },
    async loadReplayableSessions() {
      this.loadingSessions = true;
      try {
        const contentPackageName = "vr_roleplay_praise";
        const contentPackageVersion = "0.0.2";
        const response = await api.getReplayableUnits(contentPackageName, contentPackageVersion);
        console.log("response",response)
        if (!Array.isArray(response)) {
          console.error("Invalid response structure:", response);
          return;
        }

        this.replayableSessions = response
            .map(session => {
              const activity = session.activityUnit?.activity || {};
              const translations = activity.translations || [];
              const translation = translations.find(t => t.locale === 'en') || translations[0];
              const createdAt = new Date(session.createMoment).toLocaleDateString();
              return {
                originalProcedureId: session.procedure?.id || 'No originalProcedureId',
                originalUnitId: session?.id || 'No originalUnitId',
                id: session.activityUnit?.id || 'Unknown ID',
                title: `${translation?.text || 'Untitled session'} - ${createdAt}`,
                //carePlanUnitId: session.procedure?.carePlanUnit.id || 'No carePlanUnitId',
                //carePlanUuid: session.procedure?.carePlanUnit.carePlan.uuid || 'No carePlanUuid',
              };
            });

        console.log("Replayable sessions loaded:", this.replayableSessions);
      } catch (error) {
        console.error("Error loading replayable sessions:", error);
      } finally {
        this.loadingSessions = false;
      }
    },
    async getActiveProcedureStream(patientId) {
        try {
          this.eventSource = await api.getStream(patientId);

          this.eventSource.onmessage = (event) => {
            console.log("Event received:", event);
            this.handleStreamEvent(event.data);
          };

          this.eventSource.onerror = (error) => {
            console.error("Stream error:", error);
            this.reconnectStream(patientId);
          };
        } catch (error) {
          console.error("Error initializing event stream:", error);
        }
    },

    async createReplay() {
      try {
        const selectedSession = this.replayableSessions.find(
            session => session.id === this.selectedSession
        );
        if (!selectedSession) {
          console.error("Selected session is invalid or missing carePlanUuid and/or carePlanUnitId");
          return;
        }
        console.log("selectedSession", selectedSession)
        const originalProcedureId = selectedSession.originalProcedureId;
        const originalUnitId = selectedSession.originalUnitId;
        const replayPayload = {
          activityUnitId: this.activityUnitId,
          targetProcedureId: this.targetProcedureId,
        };

          const replayUnitResponse = await api.postProcedureUnitReplay(originalProcedureId, originalUnitId, replayPayload);
          console.log("Replay Unit Response", replayUnitResponse);

          if (replayUnitResponse) {
            console.log("Replay unit created successfully", replayUnitResponse);
            this.replayId = replayUnitResponse.id;
            this.replayCreated = true;
            this.loadProcedureResults();

          }

      } catch (error) {
        console.error("Error creating replay:", error);
      }
    },
    handleStreamEvent(data) {
      const eventData = JSON.parse(data);
      console.log("Parsed event data:", eventData);
      const audioPlayer = this.$refs.globalAudioPlayer;
      switch (eventData.type) {
        case 'RESPONSE_REPLAY_JUMP_TO':
          audioPlayer.currentTime = parseFloat(eventData.data);
          break;
        case 'RESPONSE_REPLAY_PLAY':
          audioPlayer.play();
          this.isPlaying = true;
          break;
        case 'RESPONSE_REPLAY_PAUSE':
          audioPlayer.pause();
          this.isPlaying = false;
          break;
        case 'STATUS_EXIT':
          this.emiteSessionControlEvent("STATUS_EXIT",audioPlayer.currentTime);
          break;
        default:
          console.warn("Unhandled event type:", eventData.type);
      }
    },

    async reconnectStream(patientId) {
      console.log("Attempting to reconnect to stream...");
      if (this.eventSource) {
        this.eventSource.close();
      }
      setTimeout(() => this.getActiveProcedureStream(patientId), 3000);
    },

    async loadProcedureResults(patientId) {
      try {
        if (!this.targetProcedureId || !this.activityUnitId) {
          console.error("Missing targetProcedureId or activityUnitId for loading results.");
          return;
        }
        const response = await api.getProcedureResults(patientId, this.targetProcedureId, this.replayId);
        this.results = Array.isArray(response.data)
            ? response.data.filter(result =>
                result.resultTemplate?.type === "AUDIO" || result.resultTemplate?.type === "MICROPHONE_AUDIO"
            )
            : [];
        this.loadingResults = false;

        for (const result of this.results) {
          await this.getResultAudio(patientId, result.id);
        }
        this.allAudiosLoaded = true;
        if (this.results.length > 0) {
          this.createCombinedAudio();
        }

      } catch (error) {
        console.error("Error loading procedure results:", error);
      }
    },
    async getResultAudio(patientId, resultId) {
      try {
        const response = await api.getResultAudioFileReplay(patientId, this.targetProcedureId, this.replayId, resultId, {
          responseType: 'blob'
        });

        if (response) {
          const audioBlob = new Blob([response], { type: 'audio/wav' });
          const audioUrl = URL.createObjectURL(audioBlob);
          this.audioElements.push(new Audio(audioUrl));

        } else {
          console.error("No audio data found for result ID", resultId);
        }
      } catch (error) {
        console.error("Error fetching audio:", error);
      }
    },
    async createCombinedAudio() {
      // Create an audio context for mixing audio
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();

      try {
        const audioBuffers = [];
        for (const audioElement of this.audioElements) {
          const response = await fetch(audioElement.src);
          const arrayBuffer = await response.arrayBuffer();
          const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
          audioBuffers.push(audioBuffer);
        }

        // Determine the duration of the longest audio
        const maxDuration = Math.max(...audioBuffers.map(buffer => buffer.duration));

        // Create a buffer to hold the mixed audio
        const numberOfChannels = Math.max(...audioBuffers.map(buffer => buffer.numberOfChannels));
        this.combinedAudioBuffer = this.audioContext.createBuffer(
            numberOfChannels,
            this.audioContext.sampleRate * maxDuration,
            this.audioContext.sampleRate
        );

        audioBuffers.forEach(buffer => {
          for (let channel = 0; channel < buffer.numberOfChannels; channel++) {
            const combinedChannelData = this.combinedAudioBuffer.getChannelData(channel);
            const bufferChannelData = buffer.getChannelData(channel);
            for (let i = 0; i < bufferChannelData.length; i++) {
              combinedChannelData[i] += bufferChannelData[i];
            }
          }
        });

        const source = this.audioContext.createBufferSource();
        source.buffer = this.combinedAudioBuffer;
        source.connect(this.audioContext.destination);

        // Assign to the audio player control
        this.$refs.globalAudioPlayer.src = URL.createObjectURL(await this.bufferToWave(this.combinedAudioBuffer));
      } catch (error) {
        console.error("Error creating combined audio:", error);
      }
    },
    async bufferToWave(buffer) {
      // Convert the audio buffer to a WAV Blob
      const numOfChannels = buffer.numberOfChannels;
      const length = buffer.length * numOfChannels * 2 + 44;
      const bufferView = new DataView(new ArrayBuffer(length));
      let offset = 0;

      // Write WAVE header
      function writeString(data) {
        for (let i = 0; i < data.length; i++) {
          bufferView.setUint8(offset + i, data.charCodeAt(i));
        }
        offset += data.length;
      }

      writeString("RIFF");
      bufferView.setUint32(offset, length - 8, true);
      offset += 4;
      writeString("WAVE");
      writeString("fmt ");
      bufferView.setUint32(offset, 16, true);
      offset += 4;
      bufferView.setUint16(offset, 1, true);
      offset += 2;
      bufferView.setUint16(offset, numOfChannels, true);
      offset += 2;
      bufferView.setUint32(offset, buffer.sampleRate, true);
      offset += 4;
      bufferView.setUint32(offset, buffer.sampleRate * numOfChannels * 2, true);
      offset += 4;
      bufferView.setUint16(offset, numOfChannels * 2, true);
      offset += 2;
      bufferView.setUint16(offset, 16, true);
      offset += 2;
      writeString("data");
      bufferView.setUint32(offset, length - 44, true);
      offset += 4;

      // Write PCM data
      for (let i = 0; i < buffer.length; i++) {
        for (let channel = 0; channel < numOfChannels; channel++) {
          const sample = buffer.getChannelData(channel)[i] * 32767.5;
          bufferView.setInt16(offset, Math.max(-32768, Math.min(32767, sample)), true);
          offset += 2;
        }
      }

      return new Blob([bufferView], { type: "audio/wav" });
    },
    showLoadingScreen(response) {
      console.log("Show loading screen with response:", response);
    },
    playAllAudios() {
      const audioPlayer = this.$refs.globalAudioPlayer;
      if (audioPlayer) {
        audioPlayer.play().then(() =>{this.emiteSessionControlEvent("REQUEST_REPLAY_PLAY",audioPlayer.currentTime)}).catch((error) => {
          console.error("Error trying to play the combined audio:", error);
        });
        this.isPlaying = true;
      }
    },
    pauseAllAudios() {
      const audioPlayer = this.$refs.globalAudioPlayer;
      if (audioPlayer) {
        audioPlayer.pause();
        this.emiteSessionControlEvent("REQUEST_REPLAY_PAUSE",audioPlayer.currentTime)
        this.isPlaying = false;
      }
    },
    syncAudioTime() {
      const audioPlayer = this.$refs.globalAudioPlayer;
      if (this.isPlaying && audioPlayer) {
        //this.emiteSessionControlEvent("REQUEST_REPLAY_JUMP_TO",audioPlayer.currentTime)
      }
    },
    syncSeeked() {
      const audioPlayer = this.$refs.globalAudioPlayer;
      if (audioPlayer) {
        this.emiteSessionControlEvent("REQUEST_REPLAY_JUMP_TO",audioPlayer.currentTime)
      }
    },
    handleFinish() {
      const audioPlayer = this.$refs.globalAudioPlayer;
        this.emiteSessionControlEvent("REQUEST_FINISH",audioPlayer.currentTime)

    },
    async emiteSessionControlEvent(type,time) {
      this.emitEventProcedure({
        sessionControlEvent: {
          type,
          clientCreateMoment: new Date(),
          client: "website",
          data: parseFloat(time),
        },
      });
    },
    async emitEventProcedure(payload) {
      api.postEventProcedure(
          this.patientDetails.id,
          this.targetProcedureId,
          this.replayId,
          payload
      );
    },
    async startReplay() {
      try {
        // GET /procedures/{id}/units/{unitId}/events/stream/
        const getStreamResponse = await api.getStreamReplay(this.targetProcedureId, this.replayId);
        console.log("GET stream response:", getStreamResponse);

        // POST /procedures/{id}/units/{unitId}/events/ con el payload
        this.emiteSessionControlEvent("REQUEST_START", 0)

        console.log("Replay started successfully");

      } catch (error) {
        console.error("Error starting replay:", error);
      }
    },
    toggleAllAudios() {
      const audioPlayer = this.$refs.globalAudioPlayer;
      if (audioPlayer) {
        if (this.isPlaying) {
          this.pauseAllAudios();
          audioPlayer.pause();
        } else {
          this.playAllAudios();
          audioPlayer.play();
        }
      }
    }
  }
};
</script>
<style scoped>
.custom-audio-player {
  width: 100%;
  border-radius: 5px;
}

.audio-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}
</style>
