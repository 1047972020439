<template>
  <v-card variant="text" class="mx-auto rounded-lg">
    <v-container fluid align="center">
      <v-col :cols="4" align="start" justify="start">
        <v-row dens align="start" justify="start">
          <v-card-title>Frequently Asked Questions (TP)</v-card-title>
        </v-row>
        <v-row dens align="start" justify="start">
          <v-card-text class="ma-8 pa-0 ml-4 mr-4 text-start">
            Q1. Vivamus neque ante, viverra non luctus nec, molestie in mauris. Fusce et
            volutpat diam, ut suscipit nulla. Fusce venenatis odio pellentesque lacinia
            tincidunt. Maecenas eu neque id leo vulputate faucibus ut vitae dolor.
            Vestibulum enim erat, condimentum eu quam vel, volutpat ultrices nisi.
            Maecenas placerat, sem a efficitur tempus, massa dui fringilla dui, vestibulum
            sollicitudin orci ligula nec leo. Etiam rhoncus fringilla aliquet. Nulla
            sollicitudin dignissim sem vel ultricies. Maecenas augue lorem, euismod eget
            mauris id, sagittis consectetur urna. Fusce quis congue arcu.
          </v-card-text>
        </v-row>
        <v-row dens align="start" justify="start">
          <v-card-text class="ma-8 pa-0 ml-4 mr-4 text-start">
            Q2. Vivamus neque ante, viverra non luctus nec, molestie in mauris. Fusce et
            volutpat diam, ut suscipit nulla. Fusce venenatis odio pellentesque lacinia
            tincidunt. Maecenas eu neque id leo vulputate faucibus ut vitae dolor.
            Vestibulum enim erat, condimentum eu quam vel, volutpat ultrices nisi.
            Maecenas placerat, sem a efficitur tempus, massa dui fringilla dui, vestibulum
            sollicitudin orci ligula nec leo. Etiam rhoncus fringilla aliquet. Nulla
            sollicitudin dignissim sem vel ultricies. Maecenas augue lorem, euismod eget
            mauris id, sagittis consectetur urna. Fusce quis congue arcu.
          </v-card-text>
        </v-row>
        <!--
            <v-row dens align="center" justify="center">
                <v-btn variant="outlined" class="mt-8" @click="route('/dashboard3')"> Start </v-btn>
            </v-row>
            -->
      </v-col>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "HelpViewTherapist",
  data: () => ({}),
  props: {},
  components: {},
  mounted: function () {},
  methods: {},
};
</script>

<style></style>
