<template>
  <v-row
      class="pa-0 ma-0"
      style="background-color: white; justify-content: center; align-items: center"
  >
    <v-card-title v-if="item"> {{ item.title }} - {{ item.date }}</v-card-title>
    <v-spacer></v-spacer>
    <v-btn variant="flat" prepend-icon="mdi-close" @click="onClose">
      <template v-slot:prepend>
        <v-icon color="success"></v-icon>
      </template>
      {{$t("overlay_btn_close")}}
    </v-btn>
  </v-row>
  <v-progress-linear
      :model-value="0"
      :max="100"
      height="3"
      color="#28B9AF"
      class="mr-16"
  ></v-progress-linear>
  <center>
    <v-card class="pa-8 mx-4 mt-4 mb-4 rounded-lg" variant="elevated" width="80%">
      <v-row class="pa-0 ma-0 fill-height">
        <v-col :cols="12">
          <v-list v-if="units.length" style="overflow-y: auto">
            <v-list-item
                v-for="(unit, i) in units"
                :key="i"
                class="pa-0 ma-0"
                align="center"
            >
              <v-card-title class="ma-0 pa-0 ml-4 mr-4 mb-2">
                {{unit.question}}
              </v-card-title>
              <v-card-text style="width:60%" v-html="unit.description"></v-card-text>

              <!-- Audio Player -->
              <div v-if="unit.audioUrl" class="audio-container">
                <audio
                    ref="globalAudioPlayer"
                    :src="unit.audioUrl"
                    controls
                    class="audio-player mb-8"
                    @play="handleAudioPlay(i)"
                    @pause="handleAudioPause"
                    @timeupdate="syncAudioTime(i)"
                ></audio>
              </div>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
  </center>
</template>

<script>
import { ref } from 'vue';
import {
  getTextByLanguage,
  getDescriptionByLanguage
} from "@/scripts/common/utils";
import { useCurrentSessionStore } from "@/stores/currentSessionStore";
import api from "@/scripts/api/api";
import { getUser } from "@/scripts/procedureEngine";

export default {
  name: "NotesViewShow1",
  setup() {
    const units = ref([]);
    const sessionStore = useCurrentSessionStore();
    return {
      units,
      sessionStore
    };
  },
  data() {
    return {
      item: null,
      audioContext: null,
      audioRefs: [],
    };
  },
  mounted: async function () {
    this.item = this.sessionStore.getItem;

    const idProcedure = this.item.item.id;

    this.units = await Promise.all(this.item.item.units.map(async (unit) => {
      const unitId = unit.id;
      const userData = getUser();
      const userId = userData.id;

      let audioUrl = null;

      try {
        // Obtener los resultados del procedimiento
        const results = await api.getProcedureResults(userId, idProcedure, unitId);

        if (results.data && results.data.length > 0) {
          const resultId = results.data[0].id;

          // Obtener el archivo de audio como un blob
          const response = await api.getResultAudioFileReplay(userId, idProcedure, unitId, resultId, {
            responseType: 'blob',
          });

          if (response) {
            // Crear un Blob a partir de la respuesta y construir la URL
            const audioBlob = new Blob([response], { type: 'audio/ogg' });
            audioUrl = URL.createObjectURL(audioBlob);
            console.log("Audio Blob URL:", audioUrl);
          } else {
            console.error("Expected Blob but received:", response);
          }
        }
      } catch (error) {
        console.error("Error fetching audio data:", error);
      }

      return {
        question: getTextByLanguage(unit.activityUnit.contentPackage.translations, this.$i18n),
        description: getDescriptionByLanguage(unit.activityUnit.contentPackage.translations, this.$i18n),
        audioUrl,
        isDownloaded: !!audioUrl,
      };
    }));
  },
  methods: {
    getTextByLanguage(item, i18n) {
      return getTextByLanguage(item, i18n);
    },
    getDescriptionByLanguage(item, i18n) {
      return getDescriptionByLanguage(item, i18n);
    },
    onClose() {
      this.$router.push("/dashboard3");
    },
    initializeAudioContext() {
      if (!this.audioContext) {
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      }
    },
    async bufferToWave(buffer) {
      // Convert Blob to ArrayBuffer using FileReader
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async (event) => {
          const arrayBuffer = event.target.result;
          if (!this.audioContext) {
            this.initializeAudioContext();
          }

          // Decode the ArrayBuffer to AudioBuffer
          this.audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
            const numOfChannels = audioBuffer.numberOfChannels;
            const length = audioBuffer.length * numOfChannels * 2 + 44;
            const bufferView = new DataView(new ArrayBuffer(length));
            let offset = 0;

            // Write WAVE header
            function writeString(data) {
              for (let i = 0; i < data.length; i++) {
                bufferView.setUint8(offset + i, data.charCodeAt(i));
              }
              offset += data.length;
            }

            writeString("RIFF");
            bufferView.setUint32(offset, length - 8, true);
            offset += 4;
            writeString("WAVE");
            writeString("fmt ");
            bufferView.setUint32(offset, 16, true);
            offset += 4;
            bufferView.setUint16(offset, 1, true);
            offset += 2;
            bufferView.setUint16(offset, numOfChannels, true);
            offset += 2;
            bufferView.setUint32(offset, audioBuffer.sampleRate, true);
            offset += 4;
            bufferView.setUint32(offset, audioBuffer.sampleRate * numOfChannels * 2, true);
            offset += 4;
            bufferView.setUint16(offset, numOfChannels * 2, true);
            offset += 2;
            bufferView.setUint16(offset, 16, true);
            offset += 2;
            writeString("data");
            bufferView.setUint32(offset, length - 44, true);
            offset += 4;

            // Write PCM data
            for (let i = 0; i < audioBuffer.length; i++) {
              for (let channel = 0; channel < numOfChannels; channel++) {
                const sample = audioBuffer.getChannelData(channel)[i] * 32767.5;
                bufferView.setInt16(offset, Math.max(-32768, Math.min(32767, sample)), true);
                offset += 2;
              }
            }

            resolve(new Blob([bufferView], { type: "audio/wav" }));
          }, reject);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsArrayBuffer(buffer);
      });
    },

    handleAudioPlay(index) {
      console.log("Audio started playing");
      this.initializeAudioContext();

      // Asegurarse de que el audio se puede reproducir
      const audioPlayer = this.$refs.globalAudioPlayer[index];
      if (audioPlayer) {
        const playPromise = audioPlayer.play();
        if (playPromise !== undefined) {
          playPromise.catch((error) => {
            console.error("Error during audio play:", error);
          });
        }
      }
    },
    handleAudioPause() {
      console.log("Audio paused");
    },
    syncAudioTime(index) {
      console.log(`Syncing audio time for player ${index}...`);
    },
  },
};
</script>

<style scoped>
.audio-player {
  width: 100%;
  margin-top: 20px;
  border-radius: 5px;
}
</style>
