<template>
  <v-dialog
      v-model="dialog"
      persistent
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title
          v-show="!!message"
          class="pa-4 black--text"
          style="color: options.color;"
      >{{ title }}</v-card-title>
      <!--
      <v-card-text
          v-show="!!message"
          class="pa-4 black--text"
          v-html="message"
      ></v-card-text>
      
      <v-btn class="pa-0 ma-4" v-for="(item, index) in items" :key="index">
        {{ item.text }}
      </v-btn>
      -->
      <v-card-actions class="pt-3">
        <v-spacer></v-spacer>
        <v-btn
            v-if="!options.noconfirm"
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="cancel"
        >{{items[0].text}}</v-btn
        >
        <v-btn
            :color="options.color"
            class="body-2 font-weight-bold"
            outlined
            @click="agree"
        >{{items[1].text}}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDlg",
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      items: null,
      options: {
        color: "#28B9AF",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
    };
  },

  methods: {
    open(title, message, items, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.items = items;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
