<template>
  <v-container fluid class="pa-0 ma-0 fill-height" v-if="true">
    <v-row class="pa-0 ma-0 fill-height">
      <v-col :cols="12" style="height: 70%">
        <v-card
          variant="text"
          class="pa-0 ma-0"
          style="height: 100%; display: flex; flex-direction: column"
        >
          <v-row align="center" justify="center" class="pb-0">
            <v-container fill-height fluid align="center" justify="center">
              <QuestionVisual
                class="ma-0 pa-0"
                :data="questions"
                :results="preloaded_results"
                :metadata="this.data"
                :updateView="this.updateView"
                :setProgress="this.setProgress"
                :eventAbort="this.eventAbort"
                :sendResults="this.sendQuestionaireResults"
              />
            </v-container>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import QuestionVisual from "@/components/questionVisual.vue";
//import api from "@/scripts/api/api";

import {
  sendResults,
  //createProcedure,
  //startProcedure,
  //continueProcedure,
  //getNextActivity,
  //startNextProcedure,
  //getNextTaskActivity,
  //connectEventSource,
} from "@/scripts/procedureEngine";

export default {
  name: "SessionsStepQuestions",
  data: () => ({
    procedureId: 0,
    procedureUnitId: 0,
    preloaded_results: null,
  }),
  props: ["data", "updateView", "setProgress", "questions", "eventAbort"/*"onBack", "onNext"*/],
  components: { QuestionVisual },
  mounted: function () {
    if (!this.access_token) {
      this.user = JSON.parse(sessionStorage.getItem("user"));
    }
    console.log("Mounted SessionsStepQuestions with data:", this.data);
    console.log("start " + this.questions[0].name)
  },
  methods: {
    sendQuestionaireResults: async function (partial, data) {
      if (data) {
        //alert("send results")
        sendResults(data, partial)
      }
    },
  },
};
</script>

<style></style>
